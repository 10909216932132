var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  staticClass: "needs-validation",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.formSubmit($event)
                    }
                  }
                },
                [
                  _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                    _c("i", {
                      staticClass: "mdi mdi-alert-circle-outline mr-2"
                    }),
                    _vm._v("Les champs obligatoires sont marqués avec (*) ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Type de chambre *")
                          ]),
                          _c("multiselect", {
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.bookingForm.roomType.$error
                            },
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "name",
                              options: _vm.roomsTypes,
                              placeholder: "Select room type",
                              "allow-empty": false
                            },
                            on: { input: _vm.onRoomTypeChange },
                            scopedSlots: _vm._u([
                              {
                                key: "singleType",
                                fn: function(ref) {
                                  var type = ref.type
                                  return [_vm._v(_vm._s(type.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.bookingForm.roomType,
                              callback: function($$v) {
                                _vm.$set(_vm.bookingForm, "roomType", $$v)
                              },
                              expression: "bookingForm.roomType"
                            }
                          }),
                          _vm.submitted && _vm.$v.bookingForm.roomType.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.bookingForm.roomType.required
                                  ? _c("span", [
                                      _vm._v(
                                        "Le type de chambre est obligatoire."
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { attrs: { for: "formrow-password-input" } },
                          [_vm._v("Arrivé *")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.bookingForm.dateIn,
                              expression: "bookingForm.dateIn"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.bookingForm.dateIn.$error
                          },
                          attrs: { type: "date" },
                          domProps: { value: _vm.bookingForm.dateIn },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.bookingForm,
                                "dateIn",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.bookingForm.dateIn.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.bookingForm.dateIn.required
                                ? _c("span", [
                                    _vm._v("Date d'arrivé est obligatoire.")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Départ *")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.bookingForm.dateOut,
                              expression: "bookingForm.dateOut"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted && _vm.$v.bookingForm.dateOut.$error
                          },
                          attrs: { type: "date" },
                          domProps: { value: _vm.bookingForm.dateOut },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.bookingForm,
                                "dateOut",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm.submitted && _vm.$v.bookingForm.dateOut.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.bookingForm.dateOut.required
                                ? _c("span", [
                                    _vm._v("Date de Départ est obligatoire.")
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Nuités")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.bookingForm.nights,
                              expression: "bookingForm.nights"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            readonly: "",
                            name: "",
                            id: ""
                          },
                          domProps: { value: _vm.bookingForm.nights },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.bookingForm,
                                "nights",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Chambre *")
                          ]),
                          _c("multiselect", {
                            class: {
                              isDisabledDropdown:
                                _vm.bookingForm.events.roomDropDownDisabled,
                              "is-invalid":
                                _vm.submitted && _vm.$v.bookingForm.room.$error
                            },
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              label: "number",
                              options: _vm.rooms,
                              placeholder: "Select a room",
                              "allow-empty": false
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "singleRoom",
                                fn: function(ref) {
                                  var room = ref.room
                                  return [_vm._v(_vm._s(room.number))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.bookingForm.room,
                              callback: function($$v) {
                                _vm.$set(_vm.bookingForm, "room", $$v)
                              },
                              expression: "bookingForm.room"
                            }
                          }),
                          _vm.submitted && _vm.$v.bookingForm.room.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.bookingForm.room.required
                                  ? _c("span", [
                                      _vm._v("La chambre est obligatoire.")
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-lg-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Type de bénéficiaire *")
                          ]),
                          _c("multiselect", {
                            class: {
                              isDisabledDropdown:
                                _vm.bookingForm.events.roomDropDownDisabled,
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.bookingForm.beneficiaryType.$error
                            },
                            attrs: {
                              searchable: true,
                              "track-by": "id",
                              "open-direction": "bottom",
                              label: "name",
                              options: _vm.beneficiaryTypes,
                              placeholder: "",
                              "allow-empty": false
                            },
                            on: { input: _vm.onBeneficiaryTypeChange },
                            scopedSlots: _vm._u([
                              {
                                key: "singleRoom",
                                fn: function(ref) {
                                  var type = ref.type
                                  return [_vm._v(_vm._s(type.name))]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.bookingForm.beneficiaryType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.bookingForm,
                                  "beneficiaryType",
                                  $$v
                                )
                              },
                              expression: "bookingForm.beneficiaryType"
                            }
                          }),
                          _vm.submitted &&
                          _vm.$v.bookingForm.beneficiaryType.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.bookingForm.beneficiaryType.required
                                  ? _c("span", [
                                      _vm._v(
                                        "Veuillez selectionner un type de bénéficiaire."
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm.bookingForm.beneficiaryType != ""
                      ? _c("div", { staticClass: "col-lg-3" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formrow-inputCity" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.bookingForm.beneficiaryType.name
                                    ) + " *"
                                  )
                                ]
                              ),
                              !_vm.bookingForm.events.showCancelLink &&
                              _vm.bookingForm.beneficiaryType.id == "visitor"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "quickAction-a",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleQuickOccupantAdd()
                                        }
                                      }
                                    },
                                    [_vm._v("Pas dans la liste ?")]
                                  )
                                : _vm._e(),
                              _vm.bookingForm.events.showCancelLink
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "quickAction-a",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleQuickOccupantCancel()
                                        }
                                      }
                                    },
                                    [_vm._v("Annuler")]
                                  )
                                : _vm._e(),
                              _c("multiselect", {
                                class: {
                                  isDisabledDropdown: !_vm.bookingForm.events
                                    .selectVisitorFromDropDown,
                                  "is-invalid":
                                    _vm.submitted &&
                                    _vm.$v.bookingForm.beneficiaryId.$error
                                },
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "fullName",
                                  options: _vm.beneficiaries,
                                  placeholder: "",
                                  "allow-empty": false
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "singleRoom",
                                      fn: function(ref) {
                                        var benef = ref.benef
                                        return [_vm._v(_vm._s(benef.fullName))]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3877807653
                                ),
                                model: {
                                  value: _vm.bookingForm.beneficiaryId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bookingForm,
                                      "beneficiaryId",
                                      $$v
                                    )
                                  },
                                  expression: "bookingForm.beneficiaryId"
                                }
                              }),
                              _vm.submitted &&
                              _vm.$v.bookingForm.beneficiaryId.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      !_vm.$v.bookingForm.beneficiaryId.required
                                        ? _c("span", [
                                            _vm._v(
                                              "Veuillez selectionner un bénéficiaire."
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ]),
                  !_vm.bookingForm.events.selectVisitorFromDropDown &&
                  _vm.bookingForm.beneficiaryType.id == "visitor"
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("hr"),
                          _c("h5", [_vm._v("Ajouter un visiteur")]),
                          _c("hr"),
                          _c("div", [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "form-group col-lg-3" },
                                [
                                  _c("label", { attrs: { for: "message" } }, [
                                    _vm._v("Nom complet")
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.bookingForm.visitor.fullName,
                                        expression:
                                          "bookingForm.visitor.fullName"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    class: {
                                      "is-invalid":
                                        _vm.submitted &&
                                        _vm.$v.bookingForm.visitor.fullName
                                          .$error
                                    },
                                    attrs: { id: "subject", type: "text" },
                                    domProps: {
                                      value: _vm.bookingForm.visitor.fullName
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.bookingForm.visitor,
                                          "fullName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm.submitted &&
                                  _vm.$v.bookingForm.visitor.fullName.$error
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [
                                          !_vm.$v.bookingForm.visitor.fullName
                                            .required
                                            ? _c("span", [
                                                _vm._v(
                                                  "Nom de visiteur est obligatoire."
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-lg-3" },
                                [
                                  _c("label", { attrs: { for: "resume" } }, [
                                    _vm._v("Pièce d'identité")
                                  ]),
                                  _c("multiselect", {
                                    attrs: {
                                      searchable: true,
                                      "track-by": "id",
                                      label: "name",
                                      options: _vm.identityDocTypes,
                                      placeholder: "Selectionner",
                                      "allow-empty": false
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "singleMealType",
                                          fn: function(ref) {
                                            var type = ref.type
                                            return [_vm._v(_vm._s(type.name))]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2867290996
                                    ),
                                    model: {
                                      value:
                                        _vm.bookingForm.visitor.identityDocType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.bookingForm.visitor,
                                          "identityDocType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "bookingForm.visitor.identityDocType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-lg-3" },
                                [
                                  _c("label", { attrs: { for: "subject" } }, [
                                    _vm._v("N° Identité")
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.bookingForm.visitor.identityDocNo,
                                        expression:
                                          "bookingForm.visitor.identityDocNo"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "subject", type: "text" },
                                    domProps: {
                                      value:
                                        _vm.bookingForm.visitor.identityDocNo
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.bookingForm.visitor,
                                          "identityDocNo",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group col-lg-3" },
                                [
                                  _c("label", { attrs: { for: "subject" } }, [
                                    _vm._v("Commentaires")
                                  ]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.bookingForm.visitor.comments,
                                        expression:
                                          "bookingForm.visitor.comments"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "subject", type: "text" },
                                    domProps: {
                                      value: _vm.bookingForm.visitor.comments
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.bookingForm.visitor,
                                          "comments",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", { attrs: { for: "formrow-inputCity" } }, [
                          _vm._v("Commentaires *")
                        ]),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.bookingForm.comments,
                              expression: "bookingForm.comments"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { cols: "30", rows: "4" },
                          domProps: { value: _vm.bookingForm.comments },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.bookingForm,
                                "comments",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(" Enregistrer ")]
                    )
                  ])
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }