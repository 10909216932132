<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import {fetchRoomsTypesApi, fetchEmployeesListApi,fetchIdentityDocTypesApi, fetchVisitorsListApi} from "@/api/common";

import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouvelle réservation",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  import: {fetchRoomsTypesApi, fetchEmployeesListApi, fetchIdentityDocTypesApi, fetchVisitorsListApi},
  data() {
    return {
      
      title: "Nouvelle réservation",
      roomsTypes: [],
      identityDocTypes: [],
      beneficiaryTypes: [
        {
          id: "sh_employee",
          name: "Employé",
        },

        {
          id: "visitor",
          name: "Visiteur",
        },

        {
          id: "supplier_employee",
          name: "Employé Prestataire",
        },
      ],
      rooms: [],
      suppliers: [],
      visitors: [],
      employees: [],
      beneficiaries : [],
      bookingForm: {
        roomType: "",
        dateIn: "",
        dateOut: "",
        beneficiaryType: "",
        beneficiaryId: "",
        nights: 0,
        attachment: "",
        room: "",
        comments: "",
        events: {
          disabledNewVisitorShortcut: false,
          showVisitorRowForm: false,
          selectVisitorFromDropDown: true,
          showCancelLink: false,
          roomDropDownDisabled: true
        },
        visitor: {
            fullName: "",
            identityDocType: "",
            identityDocNo: "",
            comments: "",
          },
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Hébergement",
          href: "/",
        },
        {
          text: "Nouvelle réservation",
          active: true,
        },
      ],
    };
  },

  validations: {
    bookingForm: {
      roomType : { required },
      dateIn: { required },
      dateOut: { required },
      room: { required },
      beneficiaryType : { required },
      beneficiaryId: { 
        required: requiredIf(function() {
          return this.bookingForm.events.selectVisitorFromDropDown;
        }),
       },

       visitor: {
            fullName: {
                required: requiredIf(function() {
                  return !this.bookingForm.events.selectVisitorFromDropDown;
                }),
            },
          },
    },
  },
  computed: {
    dateIn(){
      return this.bookingForm.dateIn;
    },

    dateOut(){
      return this.bookingForm.dateOut;
    },
  },
  watch: {
    dateIn() {
      this._setNights();
      this.fetchAvailableRooms();
    },

    dateOut() {
      this._setNights();
      this.fetchAvailableRooms();
    },

  },

  created() {
    this.fetchSuppliersList();
    this.fetchRoomsTypesList();
    this.fetchIdentityDocTypes();
    // this.fetchRoomsList();
  },

  methods: {
    fetchIdentityDocTypes(){
      fetchIdentityDocTypesApi()
      .then((res) => (this.identityDocTypes = res.data.original.data))
              .catch(() => {})
              .finally(() => {});
    },
    _setNights(){
      var startDate = this.bookingForm.dateIn;
      var endDate = this.bookingForm.dateOut;
      var nights = this.diffInDays(startDate, endDate);
      if(!isNaN(nights)){
        if(nights > 1){
          this.bookingForm.nights = this.diffInDays(startDate, endDate);
        }else{
          this.bookingForm.nights = 0;
          this.$toast.error("La date départ doit être supérieur a la date d'arrivée");
          this.bookingForm.dateIn = "";
          this.bookingForm.dateOut = "";
        }
      }else{
        this.bookingForm.nights = 0;
      }
    },
    diffInDays(startDate, endDate){
      const diffInMs   = new Date(endDate) - new Date(startDate)
      return diffInMs / (1000 * 60 * 60 * 24) - 1;
    },
    handleQuickOccupantAdd(){
        this.bookingForm.events.disabledNewVisitorShortcut = true;
        this.bookingForm.events.showCancelLink = true;
        this.bookingForm.events.selectVisitorFromDropDown = false;
    },
    handleQuickOccupantCancel(){
        this.bookingForm.events.disabledNewVisitorShortcut = false;
        this.bookingForm.events.showCancelLink = false;
        this.bookingForm.events.selectVisitorFromDropDown = true;
        this.bookingForm.visitor.fullName = "";
        this.bookingForm.visitor.identityDocType = "";
        this.bookingForm.visitor.identityDocNo = "";
    },
    fetchAvailableRooms(){
      var type = this.bookingForm.roomType.id;
      var dateIn = this.bookingForm.dateIn;
      var dateOut = this.bookingForm.dateOut;
      if(type && dateIn && dateOut){
        this.fetchRoomsList();
      }
    },
    fetchRoomsTypesList(){
      fetchRoomsTypesApi()
      .then((res) => (this.roomsTypes = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchRoomsList() {
      this.$http
        .post("/infrastructure/rooms/list", {
          roomTypeId: this.bookingForm.roomType.id
        })
        .then((res) => {
          this.rooms = res.data.original.list; 
          this.bookingForm.events.roomDropDownDisabled = false
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    onRoomTypeChange(){
      this.fetchAvailableRooms(); // get list of rooms for specified type
    },

    onBeneficiaryTypeChange(){
      this.fetchBeneficiatyList();
    },

    _fetchCompanyEmployeesList(){
      fetchEmployeesListApi()
      .then((res) => (this.beneficiaries = res.data.original.list))
        .catch(() => { })
        .finally(() => { });
    },
    _fetchVisitorsList(){
      fetchVisitorsListApi()
            .then((res) => (this.beneficiaries = res.data.original.list))
              .catch(() => {})
              .finally(() => {});
    },
    _fetchSupplierStaffList(){},
    fetchBeneficiatyList(){
      // employees, visitors or supplier staff
      var type = this.bookingForm.beneficiaryType.id;
      switch (type) {
        case 'sh_employee':
            this._fetchCompanyEmployeesList();
        break;

        case 'visitor':
            this._fetchVisitorsList();
          
        break;

        case 'supplier_employee':
            this._fetchSupplierStaffList();
        break;
      
      }
    },

    fetchSuppliersList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => { })
        .finally(() => { });
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/ops/booking/store", this.bookingForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "ops.hosting.bookings" });
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Type de chambre *</label>

                    <multiselect
                      v-model="bookingForm.roomType"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="roomsTypes"
                      placeholder="Select room type"
                      :allow-empty="false"
                      :class="{ 'is-invalid': submitted && $v.bookingForm.roomType.$error, }"
                      @input="onRoomTypeChange"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.bookingForm.roomType.$error" class="invalid-feedback">
                      <span v-if="!$v.bookingForm.roomType.required">Le type de chambre est obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-password-input">Arrivé *</label>
                    <input type="date" :class="{ 'is-invalid': submitted && $v.bookingForm.dateIn.$error, }" v-model="bookingForm.dateIn" class="form-control"/>
                    <div v-if="submitted && $v.bookingForm.dateIn.$error" class="invalid-feedback">
                      <span v-if="!$v.bookingForm.dateIn.required">Date d'arrivé est obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Départ *</label>
                    <input type="date" :class="{ 'is-invalid': submitted && $v.bookingForm.dateOut.$error, }" v-model="bookingForm.dateOut" class="form-control"/>
                    <div v-if="submitted && $v.bookingForm.dateOut.$error" class="invalid-feedback">
                      <span v-if="!$v.bookingForm.dateOut.required">Date de Départ est obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Nuités</label>
                    <input type="text" v-model="bookingForm.nights" readonly class="form-control" name="" id="" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Chambre *</label>
                    <multiselect
                      v-model="bookingForm.room"
                      :searchable="true"
                      track-by="id"
                      label="number"
                      :options="rooms"
                      placeholder="Select a room"
                      :class="{
                        'isDisabledDropdown': bookingForm.events.roomDropDownDisabled,
                        'is-invalid': submitted && $v.bookingForm.room.$error
                      }"
                      :allow-empty="false"
                    >
                      <template slot="singleRoom" slot-scope="{ room }">{{
                        room.number
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.bookingForm.room.$error" class="invalid-feedback">
                      <span v-if="!$v.bookingForm.room.required">La chambre est obligatoire.</span>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity"
                      >Type de bénéficiaire *</label
                    >
                    <multiselect
                      v-model="bookingForm.beneficiaryType"
                      :searchable="true"
                      track-by="id"
                      open-direction="bottom"
                      label="name"
                      :options="beneficiaryTypes"
                      @input="onBeneficiaryTypeChange"
                      placeholder=""
                      :allow-empty="false"
                      :class="{
                        'isDisabledDropdown': bookingForm.events.roomDropDownDisabled,
                        'is-invalid': submitted && $v.bookingForm.beneficiaryType.$error
                      }"
                    >
                   
                      <template slot="singleRoom" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.bookingForm.beneficiaryType.$error" class="invalid-feedback">
                      <span v-if="!$v.bookingForm.beneficiaryType.required">Veuillez selectionner un type de bénéficiaire.</span>
                    </div>
                    
                  </div>
                </div>
                <!-- <div class="col-lg-3">
                  <div class="form-group">
                    <label for="formrow-inputCity">Justification de réservation *</label>
                    <input type="file" class="form-control">
                    <div v-if="submitted && $v.bookingForm.beneficiaryType.$error" class="invalid-feedback">
                      <span v-if="!$v.bookingForm.beneficiaryType.required">Veuillez selectionner un type de bénéficiaire.</span>
                    </div>
                    
                  </div>
                </div> -->
                <div class="col-lg-3" v-if="bookingForm.beneficiaryType != ''">
                  <div class="form-group">
                    <label for="formrow-inputCity">{{ bookingForm.beneficiaryType.name }} *</label>
                    <a class="quickAction-a" v-if="!bookingForm.events.showCancelLink && bookingForm.beneficiaryType.id == 'visitor'" @click="handleQuickOccupantAdd()" >Pas dans la liste ?</a>
                    <a class="quickAction-a" v-if="bookingForm.events.showCancelLink" @click="handleQuickOccupantCancel()">Annuler</a>
                    <multiselect
                      v-model="bookingForm.beneficiaryId"
                      :searchable="true"
                      track-by="id"
                      label="fullName"
                      :options="beneficiaries"
                      placeholder=""
                      :class="{
                        'isDisabledDropdown': !bookingForm.events.selectVisitorFromDropDown,
                        'is-invalid': submitted && $v.bookingForm.beneficiaryId.$error
                      }"
                      :allow-empty="false"
                    >
                      <template slot="singleRoom" slot-scope="{ benef }">{{
                        benef.fullName
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.bookingForm.beneficiaryId.$error" class="invalid-feedback">
                      <span v-if="!$v.bookingForm.beneficiaryId.required">Veuillez selectionner un bénéficiaire.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="!bookingForm.events.selectVisitorFromDropDown && bookingForm.beneficiaryType.id == 'visitor'">
                <div class="col-md-12">
                   <hr>
                  <h5>Ajouter un visiteur</h5>
                  <hr>
                  <div>
                    <div class="row">
                      <div class="form-group col-lg-3">
                        <label for="message">Nom complet</label>
                        <input id="subject" :class="{'is-invalid': submitted && $v.bookingForm.visitor.fullName.$error}" v-model="bookingForm.visitor.fullName" type="text" class="form-control" />
                        <div v-if="submitted && $v.bookingForm.visitor.fullName.$error" class="invalid-feedback">
                          <span v-if="!$v.bookingForm.visitor.fullName.required">Nom de visiteur est obligatoire.</span>
                        </div>
                      </div>
                      <div class="form-group col-lg-3">
                        <label for="resume">Pièce d'identité</label>
                        <multiselect
                          v-model="bookingForm.visitor.identityDocType"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="identityDocTypes"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template slot="singleMealType" slot-scope="{ type }">{{
                            type.name
                          }}</template>
                        </multiselect>
                        
                      </div>

                      <div class="form-group col-lg-3">
                        <label for="subject">N° Identité</label>
                        <input id="subject" v-model="bookingForm.visitor.identityDocNo" type="text" class="form-control" />
                      </div>
                      <div class="form-group col-lg-3">
                        <label for="subject">Commentaires</label>
                        <input id="subject" v-model="bookingForm.visitor.comments" type="text" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires *</label>
                    <textarea
                      v-model="bookingForm.comments"
                      class="form-control"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div>
                <button class="btn btn-primary" type="submit">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
